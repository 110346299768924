import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'aos/dist/aos.css'
import { debugContextDevtool } from 'react-context-devtool';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-quill/dist/quill.snow.css';
import LoadingAnimation from './lotties/LoadingAnimation';
const App = lazy(() => import('./App'));

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter>
      <Suspense fallback={<h5 className="w-100 h-100 d-flex justify-content-center align-items-center"><LoadingAnimation /></h5>}>
        <App />
      </Suspense>
    </BrowserRouter>

  </React.StrictMode>,
  container
);
// debugContextDevtool(container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
