const Images = {
  terminal: require("./terminalos.svg").default,
  camera: require("./cam.png").default,
  agency: require("./agency.svg").default,
  teamforceplugin: require("./teamplugin.png").default,
  agencyWhite: require("./agency-white.svg").default,
  world: require("./world.svg").default,
  worldWhite: require("./world-white.svg").default,
  wallet: require("./wallet.svg").default,
  walletWhite: require("./wallet-white.svg").default,
  lucky: require("./lucky.svg").default,
  luckyWhite: require("./lucky-white.svg").default,
  search: require("./search.svg").default,
  crypto: require("./crypto.svg").default,
  expand: require("./expand.svg").default,
  searchWhite: require("./search-white.svg").default,
  searchDark: require("./search-dark.svg").default,
  forex: require("./forex.svg"),
  investments: require("./investments.svg").default,
  loans: require("./loans.svg").default,
  other: require("./other.svg").default,
  triangle: require("./triangle.svg").default,
  seeLogo: require("./see.svg").default,
  playLogo: require("./play.svg").default,
  agencyDark: require("./agency-dark.svg").default,
  rightArrow: require("./arrow-greater.svg").default,
  add: require("./add.svg").default,
  addDark: require("./add-dark.svg").default,
  face: require("./face.png").default,
  apps: require("./apps.svg").default,
  appsDark: require("./apps-dark.svg").default,
  question: require("./question-mark.svg").default,
  questionMark: require("./question-mark.svg").default,
  questionMarkDark: require("./question-mark-dark.svg").default,
  vaultIcon: require("./Vault _ Icon _ White _ Transparent 1.svg").default,
  vaultIconInvert: require("./vault-icon-invert.svg").default,
  gxLiveIcon: require("./gx-live.svg").default,
  gxLiveIconInvert: require("./gx-live.svg").default,
  snapPay: require("./snap-pay.svg").default,
  snapPayInvert: require("./snap-pay-invert.svg").default,
  nitrogen: require("./nitrogen.svg").default,
  nitrogenInvert: require("./nitrogen-invert.svg").default,
  deposit: require("./deposit.svg").default,
  withdraw: require("./withdraw.svg").default,
  external: require("./external.svg").default,
  internal: require("./internal.svg").default,
  request: require("./request.svg").default,
  withdrawExternal: require("./w-external.svg").default,
  withdrawInternal: require("./w-internal.svg").default,
  withdrawTransfer: require("./w-transfer.svg").default,
  threeArrow: require("./three-arrow.svg").default,
  createNew: require("./create-new.svg").default,
  logOut: require("./logout.svg").default,
  personalSettings: require("./personal-settings.svg").default,
  sidebarDeposit: require("./sidebar-deposit.svg").default,
  sidebarWithdrawal: require("./sidebar-withdrawal.svg").default,
  sidebarTransfer: require("./sidebar-transfer.svg").default,
  sidebarTrade: require("./sidebar-trade.svg").default,
  sidebarInvestment: require("./sidebar-investment.svg").default,
  sidebarCrypto: require("./sidebar-crypto.svg").default,
  sidebarFiat: require("./sidebar-fiat.svg").default,

  sidebarDepositInvert: require("./sidebar-deposit-invert.svg").default,
  sidebarWithdrawalInvert: require("./sidebar-withdrawal-invert.svg").default,
  sidebarTransferInvert: require("./sidebar-transfer-invert.svg").default,
  sidebarTradeInvert: require("./sidebar-trade-invert.svg").default,
  sidebarInvestmentInvert: require("./sidebar-investment-invert.svg").default,
  sidebarCryptoInvert: require("./sidebar-crypto-invert.svg").default,
  sidebarFiatInvert: require("./sidebar-fiat-invert.svg").default,

  copy: require("./copy.svg").default,
  copyInvert: require("./copy-invert.svg").default,

  threeBets: require("./3-bets.svg").default,
  nextButton: require("./next-button.svg").default,
  nextButtonInvert: require("./next-button-invert.svg").default,

  instaCryptoLogo: require("./insta-crypto-logo.svg").default,
  instaCrypto: require("./insta-crypto.png").default,
  android: require("./android-icon-by-Vexels.svg").default,
  apple: require("./icons8-apple-logo.svg").default,

  tradeLogo: require("./trade-logo.svg").default,
  fancyNext: require("./fancy-arrow.svg").default,
  loading: require("./loading.png").default,
  gxMainLogo: require("./gx-white-logo.png").default,

  dgpayment: require("./main-logo-dgpayment.svg").default,
  dgpaymentTitle: require("./dgpayments.svg").default,
  dgpaymentTitleInvert: require("./dgpayments-invert.svg").default,

  commerce: require("./commerce.svg").default,
  affiliates: require("./affiliates.svg").default,

  accountTheme: require("./account-theme.svg").default,
  accountCompliance: require("./account-compliance.svg").default,
  accountProfile: require("./2fa.png").default,
  accountLogout: require("./account-logout.svg").default,

  appsCommerce: require("./commerce.svg").default,
  appsBrokerApp: require("./apps-broker.svg").default,
  appsAndroid: require("./apps-android.svg").default,
  appsAndroidLight: require("./apps-android-light.svg").default,
  appsApple: require("./apps-apple.svg").default,
  appsAppleLight: require("./apps-apple-light.svg").default,
  appsAdmin: require("./apps-admin.svg").default,
  appsVSA: require("./vsa.svg").default,

  earnings: require("./earnings.svg").default,
  friendsLogo: require("./friends-logo.svg").default,
  investLogo: require("./invest-logo.svg").default,
  bitcoin: require("./bitcoin.svg").default,
  terminalTitle: require("./terminal-title.svg").default,
  terminalLogo: require("./terminal-logo.svg").default,

  greenTriangle: require("./green-triangle.svg").default,
  redTriangle: require("./red-triangle.svg").default,
  appsVSA: require("./vsa.svg").default,
  flag: require("./flag.svg").default,

  sendFlyer: require("./send-flyer.svg").default,
  connect: require("./connect.svg").default,
  blockCheck: require("./blockcheck.svg").default,
  fundsQR: require("./funds-qr.svg").default,
  fundsLive: require("./funds-live.svg").default,
  fundsUser: require("./funds-user.svg").default,

  navbarForex: require("./navbar-forex.svg").default,
  navbarCrypto: require("./navbar-crypto.svg").default,
  navbarMoney: require("./navbar-money.svg").default,
  navbarIced: require("./navbar-iced.svg").default,

  pasteIcon: require("./paste-icon.svg").default,
  APIMachine: require("./api-machine.svg").default,
  DeployMachine: require("./deploy-machine.svg").default,
  developerTools: require("./developer-tool.svg").default,
  instaCrypto: require("./instacrypto-logo.svg").default,

  bcByYou: require("./bc-by-you.svg").default,
  bcBySomeoneElse: require("./bc-someone-else.svg").default,
  bcByApp: require("./bc-by-app.svg").default,
  qrBlue: require("./qr-blue.svg").default,
  mailIcon: require("./mail-icon.svg").default,
  textIcon: require("./text-icon.svg").default,
  friendsIcon: require("./friendIcon.svg").default,

  hfConnect: require("./hf-connect.svg").default,
  hfVault: require("./hf-vault.svg").default,
  hfGlive: require("./hf-live.svg").default,
  hfBlockcheck: require("./hf-blockcheck.svg").default,

  hfConnectSmall: require("./hfConnectSmall.svg").default,
  hfVaultSmall: require("./hfVaultSmall.svg").default,
  hfGliveSmall: require("./hfGLiveSmall.svg").default,
  hfBlockcheckSmall: require("./hfBlockcheckSmall.svg").default,

  smallMoneyIcon: require("./money-small.svg").default,
  smallIcedIcon: require("./iced-small.svg").default,
  assetIo: require("./asset-icon.svg").default,

  tellerBox: require("./teller-box.svg").default,
  connectBox: require("./connect-box.svg").default,
  vaultBox: require("./vault-box.svg").default,
  engagementLogo: require("./engagement.svg").default,
  engagementFullLogo: require("./engagement-full-logo.svg").default,
  engagementFullLogoWhite: require("./engagement-full-logo-white.svg").default,
  eVideo: require("./e-video.svg").default,
  eArticle: require("./e-article.svg").default,
  chatIcon: require("./chat-icon.svg").default,
  chatsIoIcons: require("./chats-io-icon.svg").default,
  fileUpload: require("./file-upload.svg").default,
  sendIcon: require("./send-icon.svg").default,

  appStoreAndroid: require("./app-store-android.svg").default,
  appStoreApple: require("./app-store-apple.svg").default,
  appStoreWindows: require("./app-store-windows.svg").default,
  appStoreLinux: require("./apps-store-linux.svg").default,
  pluginBlockCheck: require("./plugin-blockcheck.svg").default,
  pluginBlockCheckDark: require("./plugin-blockcheck-dark.svg").default,
  pluginTerminal: require("./plugin-terminals.svg").default,
  pluginTerminalDark: require("./plugin-terminal-dark.svg").default,
  pluginBrokerApp: require("./plugin-broker-app.svg").default,
  pluginChatIo: require("./chat-icon.svg").default,
  pluginChatIoDark: require("./chat-icon-dark.svg").default,
  pluginSpendCrypto: require("./plugin-spend-crypto.svg").default,
  pluginEngagement: require("./plugin-engagement.svg").default,
  pluginNitrogen: require("./apps-nitogen.svg").default,

  AccountIcon: require("./AccountIcon.svg").default,
  pluginAPIMachine: require("./apps-api-machine-logo.svg").default,
  pluginsMails: require("./apps-mail.svg").default,
  pluginOnHold: require("./only-onhold.svg").default,
  pluginDDCloud: require("./APPS-DDCLOUD.svg").default,
  pluginAffiliatedBank: require("./apps-affilliate-bank.svg").default,

  chatAdd: require("./Deposit Cash.svg").default,
  chatSend: require("./Withdraw Cash.svg").default,
  chatTrade: require("./Trade.svg").default,
  chatInvest: require("./invest.svg").default,
  chatRedirect: require("./Redirect.svg").default,
  chatSupport: require("./Support.svg").default,
  chatBot: require("./Bot.svg").default,
  learnCrypto: require("./learn-crypto.svg").default,
  learnMoneyMarket: require("./learn-money-market.svg").default,
  learnOther: require("./learn-other.svg").default,
  learnVideos: require("./learn-videos.svg").default,
  learnArticle: require("./learn-article.svg").default,

  uploadLogo: require("./upload-logo.svg").default,
  justBrain: require("./just-brain.svg").default,
  editIcon: require("./edit-icon.svg").default,

  instaCryptoMainLogo: require("./insta-crypto-main.svg").default,
  instaDepositFiat: require("./deposit-fiat.svg").default,
  instaWithdrawFiat: require("./withdraw-fiat.svg").default,
  instaBuyCrypto: require("./buy-crypto.svg").default,
  contactEmail: require("./contact-mail.svg").default,
  contactChats: require("./contact-chats.svg").default,
  contactCall: require("./contact-call.svg").default,
  contactVideo: require("./contact.video.svg").default,
  instaCryptoOnlyLogo: require("./instacrypto-only-logo.svg").default,
  instaCryptoOnlyLogoDark: require("./instacrypto-only-logo-dark.svg").default,

  transactionCrypto: require("./transaction-crypto.svg").default,
  transactionFiat: require("./transaction-fiat.svg").default,
  transactionProcessing: require("./transaction-processing.svg").default,
  transactionCompleted: require("./transaction-completed.svg").default,
  transactionCancelled: require("./transaction-cancelled.svg").default,

  backArrow: require("./new-back-button.svg").default,

  eCancelButton: require("./e-cancel-button.svg").default,
  eEditButton: require("./e-edit-button.svg").default,
  mcbLogo: require("./mcb-logo.svg").default,

  dAcidMarketing: require("./d-acid-marketing.svg").default,
  dAlertExpress: require("./d-alert-express.svg").default,
  dChats: require("./d-chats.svg").default,
  dCrypto: require("./d-crypto.svg").default,
  dExchange: require("./d-exchange.svg").default,
  dFunds: require("./d-funds.svg").default,
  dFxCould: require("./d-fx-cloud.svg").default,
  dMailKings: require("./d-mailkings.svg").default,
  dTradeStream: require("./d-trade-stream.svg").default,
  dWhale: require("./d-whale.svg").default,

  coolEmoji: require("./cool-emoji.svg").default,

  ccsLogo: require("./ccs-logo.svg").default,
  showPassword: require("./show-password.svg").default,
  doNotShowPassword: require("./dont-show.svg").default,
  leftArrowNew: require("./left-arrow (1).svg").default,
  rightArrowNew: require("./right-arrow.svg").default,

  nvestBankLogo: require("./nvest-logo.svg").default,
  nvestBank: require("./nvestbank.svg").default,
  nvestReal: require("./nvest-real.svg").default,
  nvestDisplay: require("./nvest-display.svg").default,
  serverLogo: require("./server-logo.svg").default,
  replaceFile: require("./replace-file.svg").default,
  sendFile: require("./send-file.svg").default,
  tradeStreamNew: require("./trade-stream-new.svg").default,

  worldWide: require("./worldwide.svg").default,

  launchFund: require("./launchFund.svg").default,
  launchExchange: require("./launchExchnage.svg").default,

  launchDate: require("./launch-date.svg").default,
  offeringDate: require("./offering-date.svg").default,
  redemptionDate: require("./redemption-date.svg").default,
  correctIcon: require("./correct-button.svg").default,
  lxFullLogo: require("./lx-full-logo.svg").default,
  playWhite: require("./play-white.svg").default,
  bankerLogo: require("./banker-logo.svg").default,
  threeDotsIcon: require("./three-dots-icon.svg").default,
  nvestBankDark: require("./nvestbank-dark.svg").default,
  bankerDark: require("./banker-dark.svg").default,
  nvestBankLight: require("./nvestbank-light.svg").default,
  bankerLight: require("./banker-light.svg").default,
  nvestBankMarket: require("./nvest-bank-market.svg").default,

  portfolioAI: require("./portfolio-ai.svg").default,
  PAINetworth: require("./p-ai-networth.svg").default,
  PAIEarnings: require("./p-ai-earnings.svg").default,
  PAICoinMarketCap: require("./pai-coin-market.svg").default,
  PAICustom: require("./pai-custom.svg").default,
  portfolioFullLogo: require("./portfolio-full-logo.svg").default,
  calculatorPortfolio: require("./calculator-portfolio.svg").default,
  coinMarketCap: require("./coin-market-cap.svg").default,

  feesFixedLaunch: require("./fees-fixed-launch.svg").default,
  feesVariableLaunch: require("./fees-variable-launch.svg").default,
  feesBothLunch: require("./fees-both-launch.svg").default,

  gxLiveWhiteLogo: require("./gx-live-logo-white.svg").default,
  closeTradeStream: require("./close-trade-stream.svg").default,
  TSLogo: require("./ts-logo.svg").default,

  chatsIOFull: require("./chats-io-full.svg").default,
  refreshIcon: require("./refresh-icon.svg").default,
  thisRefreshPage: require("./this-page-refresh.svg").default,
  appRefresh: require("./app-refresh.svg").default,

  whiteLogoBlockcheck: require("./white-logo-blockcheck.svg").default,
  whiteLogoTerminal: require("./white-logo-terminal.svg").default,
  whiteLogoTrade: require("./white-logo-trade.svg").default,
  whiteLogoConnect: require("./whit-logo-connect.svg").default,

  editName: require("./edit-name.svg").default,
  editCategory: require("./edit-category.svg").default,

  editPaint: require("./edit-paint.svg").default,
  editInstitutions: require("./edit-institutions.svg").default,
  editBranding: require("./edit-branding.svg").default,
  editOther: require("./edit-other.svg").default,
  fourArrow: require("./four-arrow.svg").default,
  tronLogo: require("./tron.svg").default,
  agencyWithText: require("./agency-with-text.svg").default,

  classA: require("./class-a.svg").default,
  classB: require("./class-b.svg").default,

  adsIcon: require("./ads-icon.svg").default,
  contentIcon: require("./content-icon.svg").default,
  giveawayIcon: require("./giveaway-icon.svg").default,

  gameIcon: require("./game-icon.svg").default,
  gameIconGray: require("./game-icon-gray.svg").default,
  quickTradeIcon: require("./quick-trade-icon.svg").default,
  terminalXIcon: require("./terminalX-icon.svg").default,
  personOne: require("./person-one.png").default,
  personTwo: require("./person-two.png").default,

  holdingIcon: require("./holdings-icon.svg").default,

  expandButton: require("./expand-button.svg").default,
  tradeBackButton: require("./trade-back-button.svg").default,
  goToTerminals: require("./go-to-terminals.svg").default,

  forexLogo: require("./forex-logo.svg").default,
  newsLogo: require("./news-logo.svg").default,
  offersLogo: require("./offers-logo.svg").default,
  signalsLogo: require("./signals-logo.svg").default,
  netWorthLogo: require("./networth-logo.svg").default,
  earningsLogo: require("./earnings-logo.svg").default,
  moreInformation: require("./more-information.svg").default,

  actionButton: require("./action-button.svg").default,
  transactionButton: require("./transaction-button.svg").default,
  learnButton: require("./learn-button.svg").default,

  buyCoin: require("./buy-coin.svg").default,
  sellCoin: require("./sell-coin.svg").default,
  sendCoin: require("./send-coin.svg").default,
  investCoin: require("./invest-coin.svg").default,
  checkTransactions: require("./check-transactions.svg").default,

  menuChat: require("./menu-chat.svg").default,
  menuBot: require("./menu-bot.svg").default,
  menuCall: require("./menu-call.svg").default,
  menuEmail: require("./menu-email.svg").default,
  menuMain: require("./menu-c.svg").default,
  scanner: require("./scanner.svg").default,

  dropdownGravitychain: require("./dropdown-gravitychain.svg").default,
  dropdownGarage: require("./dropdown-garage.svg").default,
  dropdownMarketsverse: require("./dropdown-marketsverse.svg").default,
  dropdownSupernova: require("./dropdown-supernova.svg").default,
  dropdownBos: require("./dropdown-bos.svg").default,
  dropdownAcid: require("./dropdown-acid.svg").default,
  dropdownViral: require("./dropdown-viralverse.svg").default,

  brokerAppFullLogo: require("./broker-app-full.svg").default,
  dropdownVault: require("./dropdown-vault.svg").default,
  dropdownConnect: require("./dropdown-connect.svg").default,
  dropdownBlockcheck: require("./dropdown-blockcheck.svg").default,
  dropdownSpendCoins: require("./dropdown-spendcoins.svg").default,
  dropdownPortfolioAI: require("./dropdown-portfolio.svg").default,
  dropdownTerminals: require("./dropdown-terminals.svg").default,
  dropdownInsta: require("./dropdown-insta.svg").default,
  dropdownInitialFund: require("./dropdown-initial-fund.svg").default,
  dropdownInitialExchange: require("./dropdown-initial-exchange.svg").default,
  dropdownVirtualPropspectus: require("./dropdwon-initial-propsectus.svg")
    .default,
  dropdownTradeStream: require("./dropdown-trade-stream.svg").default,
  dropdownMoneyMarket: require("./dropdown-money.svg").default,
  dropdownMoneyVault: require("./dropdown-iced.svg").default,
  dropdownMalls: require("./dropdown-malls.svg").default,
  dropdownRetailers: require("./dropdown-retailers.svg").default,
  dropdownClassRoom: require("./dropdown-classrooms.svg").default,

  dSFinancial: require("./ds-financial.svg").default,
  dsInvestments: require("./ds-investments.svg").default,
  dsCommerce: require("./d-commerce.svg").default,
  dsContent: require("./ds-content.svg").default,
  dsAffiliate: require("./ds-affiliate.svg").default,
  dsSocial: require("./ds-social.svg").default,
  agencyTvFull: require("./agency-tv-full.svg").default,
  tokenSwap: require("./token-swap.svg").default,
  tokenSwapBlack: require("./token-swap-black.svg").default,
  tokenSwapFull: require("./tokenswap-full.svg").default,

  myCryptoBrandWhite: require("./my-cryptobrand-white.svg").default,
  copiedLogo: require("./copied-logo.svg").default,
  contacts: require("./contact-logo.svg").default,
  meeting: require("./meeting-logo.svg").default,
  toMarketPlace: require("./to-marketplace.svg").default,
  batmanLogo: require("./batman.svg").default,

  bankerLogo: require("./banker-logo-p.svg").default,
  // manSmiling: require('./man-smiling.png'),

  cryptoStartUpFull: require("./crypto-startup-full.svg").default,
  cryptoStartUpShort: require("./crypto-startup-short.svg").default,

  //bos
  bosLogo: require("./bos-logo.svg").default,
  founder: require("./founder.svg").default,
  computer: require("./a-computer.svg").default,
  clickBusiness: require("./click-business.svg").default,
  bosControlPanel: require("./bos-control-panel.svg").default,
  bosCapital: require("./bos-capital.svg").default,
  bosMBA: require("./bos-mba.svg").default,
  bosMarketPlace: require("./bos-market-place.svg").default,
  bosMedia: require("./bos-media.svg").default,
  cancelIcon: require("./cancel-icon.svg").default,

  shortMcb: require("./short-finance.svg").default,
  shortLegal: require("./short-legal.svg").default,
  shortRetails: require("./short-retail.svg").default,
  shortEducation: require("./short-edu.svg").default,
  shortPublishing: require("./short-publication.svg").default,
  shortAccounting: require("./short-accounting.svg").default,
  bosAppStore: require("./bos-app-store.svg").default,

  bosShortLogo: require("./short_logo.png").default,
  breadCrumb: require("./bread-crumb.svg").default,
  finance: require("./finance.svg").default,
  consulting: require("./consulting.svg").default,
  gambling: require("./gambling.svg").default,
  construction: require("./construction.svg").default,
  nonProfit: require("./non-profit.svg").default,

  individual: require("./individual.svg").default,
  forCompany: require("./for-company.svg").default,

  bondDebentures: require("./bonds-debenture.svg").default,
  bondDebenturesSmall: require("./bonds-d-short.svg").default,
  bondHolding: require("./holdings-bond.svg").default,
  newBTC: require("./new-btc.svg"),
  tableResize: require("./table-resize.svg").default,
  tableSetting: require("./table-settings.svg").default,

  tableThreeDots: require("./table-three-dots.svg").default,
  tableRefresh: require("./table-refresh.svg").default,
  tableSearch: require("./table-search.svg").default,
  tableCancel: require("./table-cancel.svg").default,
  tableLeftArrow: require("./table-left-arrow.svg").default,
  tableRightArrow: require("./table-right-arrow.svg").default,

  tokenBuilder: require("./token-buillder.svg").default,
  marketFund: require("./market-fund-offering.svg").default,
  marketExchange: require("./market-exchange-offering.svg").default,
  marketBond: require("./market-bond.svg").default,
  marketEquity: require("./market-equity.svg").default,
  marketTrading: require("./market-trading-platform.svg").default,
  marketMakersLogo: require("./market-makers-logo.svg").default,
  bondOfferingLogo: require("./bond-offering-logo.svg").default,
  phoneCoin: require("./phone-coin.svg").default,

  capitalization: require("./capitalization.svg").default,
  liability: require("./liability.svg").default,

  calciWithCoins: require("./calci-with-gold.svg").default,
  poolBall: require("./pool-ball.svg").default,
  variableInterest: require("./variable-interest.svg").default,
  darkTriangle: require("./traingle-dark.svg").default,

  fancyArrowRight: require("./fancy-arrow-right.svg").default,
  homeLogo: require("./home-logo.svg").default,

  wishlist: require("./wishlist.svg").default,
  redirectLogo: require("./redirect-logo.svg").default,
  handWithBuilding: require("./hand-with-buildings.svg").default,
  fireIcon: require("./fire-icon.svg").default,
  leftArrowBos: require("./left-arrow-bos.svg").default,

  tDBonds: require("./b-t-bonds.svg").default,
  tDFunds: require("./b-t-funds.svg").default,
  tDNFT: require("./bt-nft.svg").default,
  tDOther: require("./bt-nft.svg").default,

  fundsOfferings: require("./funds-offering.svg").default,

  sharesOffering: require("./shares-offering.svg").default,
  nftOffering: require("./nft-offering.svg").default,

  maximizeButton: require("./maximize.svg").default,
  minimizeButton: require("./minimize.svg").default,
  marketLogoDark: require("./market-logo-dark.svg").default,
  NFTFullLogo: require("./nft-full-logo.svg").default,

  gxToken: require("./gx-token.svg").default,

  demo: require("./demo.svg").default,
  manWithPlan: require("./man-with-plan.svg").default,
  candleChar: require("./candle-chart.svg").default,

  allProducts: require("./all-products.svg").default,
  marketWhite: require("./markets-white.svg").default,
  deleteIconNew: require("./delete-icon-new.svg").default,
  editIconNew: require("./edit-icon-new.svg").default,

  fundsOfferingLogoNew: require("./funds-offering-new-logo.svg").default,
  SharedOfferingNewLogo: require("./shares-offering-new-logo.svg").default,
  investmentBotLogo: require("./investment-bot-logo.svg").default,

  fundsOfferingInitial: require("./funds-offering-initial.svg").default,
  sharesOfferingInitial: require("./shares-offering-initial.svg").default,
  investmentBotInitial: require("./investment-bot-initial.svg").default,

  exchangeOfferingFull: require("./exchange-ofering-full.svg").default,
  exchangeOfferingInitial: require("./exchange-offering-initial.svg").default,

  indexFullLogo: require("./index-fund-logo.svg").default,
  indexLogoInitial: require("./index-fund-initial.svg").default,

  subscriptionLogo: require("./subscriptions.svg").default,
  subscriptionLogoInitial: require("./subscriptions-initial.svg").default,

  shopBag: require("./shop-bag.svg").default,

  nftOfferingLogo: require("./nft-offering-logo.svg").default,
  nftOfferingInitial: require("./nft-offering-initial.svg").default,

  spendCoinsOfferingLogo: require("./spend-coins-logo.svg").default,
  spendCoinOfferingInitial: require("./spend-coinsinitial.svg").default,

  loadingCircle: require("./icons8-loading-circle.gif").default,

  dividendsInitial: require("./dividends-initial.svg").default,
  exchangeFullLogo: require("./exchange-full-logo.svg").default,
  exchangeLogo: require("./exchange-logo.svg").default,

  blockChainLogo: require("./Blockchain.svg").default,
  walletWithCoins: require("./wallet-with-coins.svg").default,

  expandLogo: require("./expand-logo.svg").default,

  redCancel: require("./red-cancel.svg").default,
  blueCorrect: require("./blue-correct.svg").default,

  security: require("./security.svg").default,

  waiting: require("./waiting.svg").default,

  newEditIcon: require("./edit-new-icon.svg").default,

  ripple: require("./ripple.svg").default,
  tronWeb: require("./tron-web.svg").default,
  blockChainWeb: require("./block-chain-web.svg").default,
  ethScan: require("./eth-scan.svg").default,

  priceLock: require("./price-lock.svg").default,
  priceLockInitial: require("./price-lock-initial.svg").default,
  aLogo: require("./a-logo.svg").default,

  newRedClose: require("./new-red-close.svg").default,
  newExpandLogo: require("./new-expand-logo.svg").default,
  newInfoLogo: require("./new-info-logo.svg").default,

  newBusinessesDataLogo: require("./new-businessess-data.svg").default,
  newEngagement: require("./new-engagement.svg").default,
  newOnHold: require("./new-on-hold.svg").default,
  developerIcon: require("./developer.svg").default,
  businessFullLogo: require("./business-data-full.svg").default,
  businessInitial: require("./business-data-initial.svg").default,

  mainLogo: require("./mainLogo.svg").default,
  productServices: require("./product-services.svg").default,
  pana: require("./pana.svg").default,
  bookBuildings: require("./book-buildings.svg").default,

  sociallyBD: require("./socially-bd.svg").default,
  otcDeskBD: require("./otc-desk-bd.svg").default,
  commerceBD: require("./commerce-bd.svg").default,
  investmentsBD: require("./investments-bd.svg").default,
  moneyMarketBD: require("./money-market-bd.svg").default,
  bondsBD: require("./bonds-bd.svg").default,

  customerBase: require("./customer-base-logo.svg").default,
  incorporateLogo: require("./incorporate-logo.svg").default,

  templatesLogo: require("./templates-logo.svg").default,
  companiesFull: require("./companies-full.svg").default,
  capitalizedFull: require("./capitalized-full.svg").default,
  businessAppStore: require("./business-app-store.svg").default,
  bosMediaNew: require("./bos-media-new.svg").default,
  mbaLogo: require("./mba-logo.svg").default,

  businessesNewLogo: require("./businesses-new-logo.svg").default,

  bGetStarted: require("./full_logo.png").default,
  bosAppStoreNew: require("./bos-app-store-new.svg").default,

  hrImg: require("./hr-img.png").default,
  itImg: require("./it-img.png").default,
  customerServiceImg: require("./customer-service-img.png").default,
  allAppImg: require("./all-apps-img.png").default,
  marketImg: require("./market-img.png").default,

  commWhite: require("./comm-white.svg").default,
  otcWhite: require("./otc-white.svg").default,
  externalWhite: require("./ext-white.svg").default,
  tsWhite: require("./ts-white.svg").default,
  sociallyWhite: require("./socially-white.svg").default,
  bondsWhite: require("./bonds-white.svg").default,
  investmentWhite: require("./invt-white.svg").default,
  mmNewWhite: require("./mm-new-white.svg").default,
  manVerified: require("./man-verified.svg").default,

  roundBack: require("./round-back.svg").default,
  withdrawCash: require("./withdraw-cash.svg").default,

  dikchik: require("./dikchik.svg").default,
  revenueBack: require("./revenue-back.svg").default,
  revenueVaults: require("./revenue-vaults.svg").default,
  closeNewLogo: require("./close-new-logo.svg").default,

  sbUsers: require("./sb-users.svg").default,
  sbHoldings: require("./sb-holdings.svg").default,
  sbInvest: require("./sb-invest.svg").default,
  sbSales: require("./sb-sales.svg").default,

  graySearch: require("./gray-search.svg").default,
  sbFilter: require("./sb-filter.svg").default,

  sbSettings: require("./settings.svg").default,

  cAccountLogo: require("./c-account-logo.svg").default,
  cNonProfit: require("./c-non-profit.svg").default,
  cConstruction: require("./c-construction.svg").default,
  cGambling: require("./c-gambling.svg").default,
  cConsulting: require("./c-consulting.svg").default,

  shareTokenFullLogo: require("./share-token-full.svg").default,
  newCryptoCurrencyLogo: require("./new-crypto-currency-logo.svg").default,
  newForexCurrencyLogo: require("./new-forex-currrencies.svg").default,
  avExternalAddress: require("./av-external-address.svg").default,
  avMoneyMarket: require("./av-money-market.svg").default,
  avTerminals: require("./av-terminals.svg").default,

  terminalsAvv: require("./terminals-avv.svg").default,
  customBondsAvv: require("./custom-bonds-avv.svg").default,
  p2pBondAvv: require("./p2p-bond-avv.svg").default,
  classicBondAvv: require("./classic-bond-avv.svg").default,
  mmAvv: require("./mm-avv.svg").default,
  investmentsAvv: require("./investments-avv.svg").default,
  commerceAvv: require("./commerce-avv.svg").default,
  depositsAv: require("./deposits-avv.svg").default,
  withdrawalsAvv: require("./withdrawals-avv.svg").default,
  tokenSwapAvv: require("./token-swap-avv.svg").default,

  moneyHand: require("./money-hand.svg").default,
  docHand: require("./doc-hand.svg").default,

  offeringsLogo: require("./offerings-logo.svg").default,
};

export default Images;
