import React from "react";
import Lottie from "react-lottie";
import "./loading.style.scss";
import * as animationDataLoad from "./loading.json";
import * as animationLoginLoad from "./login-loading.json";
import * as animationFetchLoad from "./fetching-animation.json";
import * as animationVerifying from "./verifying.json";
import * as animationDone from "./done.json";
import * as animationAdd from "./adding.json";
import * as animationFailed from "./failed.json";
import * as animationNoData from "./no-data.json";
import Images from "../assets/a-exporter";

export default function LoadingAnimation({
  type = "login",
  size = { height: 150, width: 150 },
  loop = true,
  style={},
  info = "",
}) {
  let animate = null,
    animateLogin = null,
    animateFetch = null,
    animateVerify = null,
    animateDone = null,
    animateAdd = null,
    animateFailed = null,
    animateNoData = null;
  animate = animationDataLoad.default;
  animateLogin = animationLoginLoad.default;
  animateFetch = animationFetchLoad.default;
  animateVerify = animationVerifying.default;
  animateDone = animationDone.default;
  animateAdd = animationAdd.default;
  animateFailed = animationFailed.default;
  animateNoData = animationNoData.default;
  const selectAnimation = () => {
    switch (type) {
      case "loading":
        return animate;
      case "login":
        return animateLogin;
      case "fetch":
        return animateFetch;
      case "verify":
        return animateVerify;
      case "done":
        return animateDone;
      case "add":
        return animateAdd;
      case "failed":
        return animateFailed;
      case "no-data":
        return animateNoData;
      default:
        return;
    }
  };

  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: selectAnimation(),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <React.Fragment>
      {type === "no-data" ? (
        <Lottie
          style={{ padding: 0 }}
          width={size.width}
          height={size.height}
          options={defaultOptions}
        />
      ) : (
        <div style={{...style}} className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <img
            onError={(e) => (e.target.src = Images.agencyDark)}
            className="animated-mm"
            style={{ height: "22%" }}
            src={Images.bosShortLogo}
          />
          {!info ? "" : <p>{info}</p>}
        </div>
      )}
    </React.Fragment>
  );
}
